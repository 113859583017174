.btn-group {
	display: flex;
	justify-content: space-between;
	margin-bottom: 16px;
}

.btn {
	position: relative;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	font-size: vwm(16px);
	line-height: 1.4;
	color: $black-color;
	border: 1px solid $black-color;
	padding: vwm(16px) vwm(32px);
	border-radius: 8px;
	transition: all 0.3s ease-out;

	@include minW(640) {
		font-size: vwt(16px);
		padding: vwt(16px) vwt(32px);
	}

	@include minW(992) {
		font-size: vwd(16px);
		padding: vwd(16px) vwd(32px);
	}

	@include minW(1440) {
		font-size: 16px;
		padding: 16px 32px;
	}


	&--square {
		border-color: $yellow-color;
		background-color: $yellow-color;
		font-size: vwm(16px);
		padding: vwm(16px) vwm(32px);
		border-radius: 0;
		margin-bottom: 6px;

		@include minW(640) {
			font-size: vwt(32px);
			padding: vwt(16px) vwt(64px);
		}

		@include minW(992) {
			font-size: vwd(24px);
			padding: vwd(16px) vwd(48px);
		}

		@include minW(1440) {
			font-size: 24px;
			padding: 16px 48px;
		}

		&::after {
			content: '';
			display: block;
			width: 100%;
			height: 2px;
			background-color: $yellow-color;
			position: absolute;
			left: 0;
			bottom: -5px;
			transition: all 0.3s ease-out;

			@include minW(640) {
				height: 4px;
				bottom: -7px;
			}
		}

		@include hover {
			border-color: $yellow-light-color;
			background-color: $yellow-light-color;

			&::after {
				background-color: $yellow-light-color;
			}
		}
	}

	&--black {
		background-color: $black-color;
		color: $white-color;
	}

	&--yellow {
		background-color: $yellow-color;
		border-color: $yellow-color;
		border-radius: 30px;
	}

	&--link {
		border-color: transparent;
		border-bottom-color: $black-color;
		padding: 0;
		border-radius: 0;
	}

	&--arrow {
		border-color: transparent;
		padding: 0;
		border-radius: 0;
		color: #808080;
		font-size: vwm(16px);

		@include minW(640) {
			font-size: vwt(32px);
		}

		@include minW(992) {
			font-size: vwd(20px);
		}

		@include minW(1440) {
			font-size: 20px;
		}

		.btn {
			&__text {
				position: relative;
				display: flex;
				align-items: center;

				&::after {
					content: '';
					display: block;
					background: url('../img/icon-arrow-right.svg') no-repeat center;
					background-size: cover;
					width: 24px;
					height: 24px;
					margin-left: 8px;
				}
			}
		}
	}

	&--icon {
		padding: vwm(16px);

		@include minW(640) {
			padding: vwt(32px);
		}

		@include minW(992) {
			padding: vwd(16px);
		}

		@include minW(1440) {
			padding: 16px;
		}

		.icon {
			margin-right: 8px;
		}

		.icon-svg {
			margin-right: 8px;
		}
	}

	&--full {
		width: 100%;
	}
}