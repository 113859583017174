.modal {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(#000, 0.85);
	overflow-x: hidden;
	overflow-y: auto;
	display: none;
	z-index: 998;

	&__dialog {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: calc(100% - 64px);
		margin: vwm(16px);

		@include minW(640) {
			margin: vwt(32px);
		}

		@include minW(992) {
			margin: vwd(32px);
		}

		@include minW(1440) {
			margin: 32px;
		}
	}

	&__main {
		position: relative;
		padding: vwm(16px);
		width: 100%;
		background-color: $white-color;
		overflow: hidden;

		@include minW(640) {
			padding: vwt(32px);
		}

		@include minW(992) {
			padding: vwd(32px);
			max-width: vwd(930px);
		}

		@include minW(1440) {
			padding: 32px;
			max-width: 930px;
		}
	}

	&__close {
		background-color: transparent;
		border: 0;
		outline: 0;
		font-size: vwm(24px);
		margin-bottom: vwm(24px);
		z-index: 9;

		@include minW(640) {
			font-size: vwt(24px);
			margin-bottom: vwt(24px);
		}

		@include minW(992) {
			font-size: vwd(24px);
			margin-bottom: vwd(24px);
		}

		@include minW(1440) {
			font-size: 24px;
			margin-bottom: 24px;
		}
	}

	&__body {
		position: relative;
		z-index: 2;
	}

	&--signin {
		.modal {
			&__main {
				max-width: 1020px;
				padding: 0;
			}

			&__close {
				position: absolute;
				right: 10px;
				top: 10px;
			}
		}
	}
}