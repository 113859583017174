.tour-vido {
	position: relative;
	padding: 0 32px;
	min-height: 612px;

	&::after {
		content: '';
		display: block;
		position: absolute;
		bottom: 60px;
		left: 50%;
		transform: translateX(-50%);
		width: 556px;
		height: 273px;
		border-radius: 50%;
		background: rgba(225, 209, 178, 0.1);
	}

	.row {
		&__col {
			&--lg-8 {
				order: 0;
			}

			&--lg-4 {
				order: 1;
			}
		}
	}

	.slick {
		position: relative;
		z-index: 3;

		&-slide {
			transition: all 0.3s ease-out;

			&:not(.slick-active) {
				+ .slick-active {
					margin-top: 0;
				}
			}
 		}
		&-active {
			margin-top: 100px;
		}
		&-current {
			margin-top: 200px;

			~ .slick-slide {
				margin-top: 0;
			}

			+ .slick-slide {
				margin-top: 100px;
			}

			.card-tour {
				&__body {
					opacity: 1;
				}
			}
		}


	}
}

.card-tour {
	&__thumb {
		transform: rotate(45deg);
		//padding: 32px;
		margin-bottom: 32px;
	}

	&__body {
		opacity: 0;
		text-align: center;
	}

	&__title {
		font-size: 32px;
		font-weight: 400;
		margin-bottom: 64px;
	}
}