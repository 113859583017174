.card-confirm {
	position: relative;
	background-color: #F5F5F5;
	padding: 32px 16px;
	margin-bottom: 16px;
	border-radius: 12px;
	font-size: 20px;

	&__title {
		font-size: 24px;
	}

	+ .card-confirm {
		margin-top: 16px;
	}
}