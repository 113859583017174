.card-room {
	border: 1px solid #D9D9D9;
	border-radius: vwm(10px);
	padding: vwm(16px);

	@include minW(640) {
		border-radius: vwt(20px);
		padding: vwt(32px);
	}

	@include minW(992) {
		border-radius: vwd(20px);
		padding: vwd(32px);
	}

	@include minW(1440) {
		border-radius: 20px;
		padding: 32px;
	}

	&__head {
		position: relative;
		margin-bottom: 32px;

		@include minW(992) {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.btn {
			padding: 10px 16px;

			// &__text {
			// 	position: relative;
			// 	display: block;
			// 	padding-left: 32px;

			// 	&::before {
			// 		content: '';
			// 		display: block;
			// 		background: url('../img/icon-calendar.svg') no-repeat center;
			// 		background-size: cover;
			// 		width: 24px;
			// 		height: 24px;
			// 		position: absolute;
			// 		top: 50%;
			// 		left: 0;
			// 		transform: translateY(-50%);
			// 	}
			// }
		}
	}

	&__title {
		margin-bottom: vwm(16px);
		font-size: vwm(24px);

		@include minW(640) {
			margin-bottom: vwm(32px);
			font-size: vwm(48px);
		}

		@include minW(992) {
			margin-bottom: 0;
			font-size: vwd(28px);
		}

		@include minW(1440) {
			font-size: 28px;
		}
	}

	&__thumb {
		margin-bottom: 32px;
	}

	&__facilities {
		font-size: 20px;
	}
}

.datepicker-avaiable {
	position: absolute;
	left: 0;
	top: calc(100% + 8px);
	background-color: #fff;
	padding: 32px;
	z-index: 2;
	box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.05);
	max-width: 424px;
	width: 100%;
	display: none;

	&__note {
		display: flex;
		align-items: center;
		color: #808080;
		line-height: 1.4;
		margin: 16px 0;

		.icon-svg {
			margin-right: 8px;
		}
	}

	&__foot {
		text-align: right;
	}

	&--show {
		display: block;
	}
}