.filter-tour {
	background-color: rgba(#fff, 0.2);
	padding: vwm(32px);
	border-radius: vwm(12px);
	width: vwm(460px);

	@include minW(640) {
		padding: vwt(32px);
		border-radius: vwt(12px);
		width: vwt(920px);
	}

	@include minW(992) {
		padding: vwd(32px);
		border-radius: vwd(12px);
		width: vwd(1120px);
	}

	@include minW(1440) {
		padding: 32px;
		border-radius: 12px;
		width: 1120px;
	}

	&__title {
		text-align: center;
	}

	&__body {
		background-color: #fff;
		position: relative;
		transition: background-color 0.3s ease-out;
	}

	&__form {
		@include minW(992) {
			display: flex;
			flex-wrap: wrap;
		}
	}

	&__label {
		display: block;
	}

	&__item {
		display: block;
		padding: vwm(24px) vwm(16px);
		transition: background-color 0.3s ease-out;
		margin-bottom: 0;

		@include minW(640) {
			padding: vwt(24px) vwt(16px);
		}

		@include minW(992) {
			width: 20%;
			flex-basis: 20%;
			padding: vwd(24px) vwd(16px);
		}

		@include minW(1440) {
			padding: 24px 16px;
		}

		&--bgwhite {
			background-color: #fff;
		}

		&--location,
		&--checkin,
		&--checkout {
			position: relative;

			@include minW(992) {
				position: static;
			}
		}

		&--tour-type,
		&--tour-out,
		&--who {
			position: relative;
		}

		&--tour-type {
			.ui-autocomplete {
				left: 50% !important;
				transform: translateX(-50%) !important;
			}
		}

		.btn {
			width: 100%;
			justify-content: center;
		}

		input {
			border: 0;
			outline: 0;
			background-color: transparent;
			font-size: vwm(20px);
			white-space: nowrap;
			overflow: hidden !important;
			text-overflow: ellipsis;
			width: 100%;

			@include minW(640) {
				font-size: vwt(20px);
			}

			@include minW(992) {
				font-size: vwd(20px);
			}

			@include minW(1440) {
				font-size: 20px;
			}
		}
	}

	&__label {
		font-weight: 700;
		color: #000;
		font-size: vwm(16px);
		margin-bottom: vwm(8px);

		@include minW(640) {
			font-size: vwt(16px);
			margin-bottom: vwt(8px);
		}

		@include minW(992) {
			font-size: vwd(16px);
			margin-bottom: vwd(8px);
		}

		@include minW(1440) {
			font-size: 16px;
			margin-bottom: 8px;
		}
	}

	&__text {
		display: block;
		color: #808080;
		font-size: vwm(20px);
		white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;

		@include minW(640) {
			font-size: vwt(20px);
		}

		@include minW(992) {
			font-size: vwd(20px);
		}

		@include minW(1440) {
			font-size: 20px;
		}

		&--value {
			color: #292929;
		}
	}

	&__date {
		position: absolute;
		background-color: #fff;
		width: 100%;
		left: 0;
		display: none;
		top: calc(100% + vwm(8px));
		padding: vwm(32px) vwm(64px);
		z-index: 2;

		@include minW(640) {
			top: calc(100% + vwt(8px));
			padding: vwt(32px) vwt(64px);
		}

		@include minW(992) {
			left: 50%;
			transform: translateX(-50%);
			width: vwd(450px);
			top: calc(100% + vwd(8px));
			padding: vwd(32px) vwd(64px);
		}

		@include minW(1440) {
			width: 450px;
			top: calc(100% + 8px);
			padding: 32px 64px;
		}

		&__title {
			text-align: center;
			font-size: vwm(24px);
			margin-bottom: vwm(32px);

			@include minW(640) {
				font-size: vwt(24px);
				margin-bottom: vwt(32px);
			}

			@include minW(992) {
				font-size: vwd(24px);
				margin-bottom: vwd(32px);
			}

			@include minW(1440) {
				font-size: 24px;
				margin-bottom: 32px;
			}
		}

		&--show {
			display: block;
		}
	}

	&--show {
		.box-filter {
			&__body {
				background-color: #D9D9D9;
			}
		}
	}
}