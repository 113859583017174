.banner {
	position: relative;
	margin-bottom: 32px;
	width: 100%;
	height: 360px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	&__title {
		position: absolute;
		bottom: 64px;
		left: 50%;
		transform: translateX(-50%);
		font-size: vwm(42px);
		color: $white-color;
		line-height: 1.2;
		margin-bottom: 0;

		@include minW(992) {
			font-size: vwt(48px);
		}

		@include minW(992) {
			font-size: vwd(64px);
		}

		@include minW(1440) {
			font-size: 64px;
		}
	}
}