.checkbox {
	position: relative;
	margin-bottom: vwm(16px);
	user-select: none;

	@include minW(640) {
		margin-bottom: vwt(16px);
	}

	@include minW(992) {
		margin-bottom: vwd(16px);
	}

	@include minW(1440) {
		margin-bottom: 16px;
	}

	&__input {
    position: absolute;
    opacity: 0;
    z-index: -1;

    &:focus {
      +span {
        &::before {
          border-color: #28303F;
        }
      }
    }

    &:checked {
      +span {
				&::before {
					border-color: #FBBC04;
					background-color: #FBBC04;
				}
        &::after {
          opacity: 1;
        }
      }
    }
  }

	&__text {
		position: relative;
    display: inline-block;
    align-items: center;
    min-height: vwm(20px);
    padding-left: vwm(30px);
    font-size: vwm(24px);
    cursor: pointer;
    color: #000;

		@include minW(640) {
			min-height: vwt(20px);
			padding-left: vwt(30px);
			font-size: vwt(24px);
		}

		@include minW(992) {
			min-height: vwd(20px);
			padding-left: vwd(30px);
			font-size: vwd(24px);
		}

		@include minW(1440) {
			min-height: 20px;
			padding-left: 30px;
			font-size: 24px;
		}

    &::before,
    &::after {
      content: '';
      position: absolute;
      display: block;
      transition: all 0.3s ease;
    }

    &::before {
			border: 2px solid #28303F;
      background-color: #fff;
			left: 0;
			top: vwm(8px);
			width: vwm(16px);
			height: vwm(16px);
			border-radius: vwm(4px);

			@include minW(640) {
				top: vwt(8px);
				width: vwt(16px);
				height: vwt(16px);
				border-radius: vwt(4px);
			}

			@include minW(992) {
				top: vwd(8px);
				width: vwd(16px);
				height: vwd(16px);
				border-radius: vwd(4px);
			}

			@include minW(1440) {
				top: 8px;
				width: 16px;
				height: 16px;
				border-radius: 4px;
			}
    }

    &::after {
      content: '';
      border-left: 2px solid #fff;
      border-bottom: 2px solid #fff;
      transform: rotate(-45deg);
      opacity: 0;
			width: vwm(10px);
			height: vwm(5px);
			top: vwm(12px);
			left: vwm(4px);

			@include minW(640) {
				width: vwt(10px);
				height: vwt(5px);
				top: vwt(12px);
				left: vwt(4px);
			}

			@include minW(992) {
				width: vwd(10px);
				height: vwd(5px);
				top: vwd(12px);
				left: vwd(4px);
			}

			@include minW(1440) {
				width: 10px;
				height: 5px;
				top: 12px;
				left: 4px;
			}
    }
	}

	&--discount {
		margin: vwm(16px) 0;

		@include minW(640) {
			margin: vwt(32px) 0;
		}

		@include minW(992) {
			margin: vwd(32px) 0;
		}

		@include minW(1440) {
			margin: 32px 0;
		}

		.checkbox {
			&__input {
				position: absolute;
				opacity: 0;
				z-index: -1;

				&:focus {
					+span {
						&::before {
							border-color: #007549;
						}
					}
				}

				&:checked {
					+span {
						&::before {
							border-color: #007549;
							background-color: #007549;
						}
						&::after {
							opacity: 1;
						}
					}
				}
			}

			&__text {
				font-size: vwm(20px);
				color: #007549;

				@include minW(640) {
					font-size: vwt(20px);
				}

				@include minW(992) {
					font-size: vwd(20px);
				}

				@include minW(1440) {
					font-size: 20px;
				}

				&::before {
					top: vwm(4px);
					border-color: #007549;

					@include minW(640) {
						top: vwt(4px);
					}

					@include minW(992) {
						top: vwd(4px);
					}

					@include minW(1440) {
						top: 4px;
					}
				}

				&::after {
					top: vwm(8px);

					@include minW(640) {
						top: vwt(8px);
					}

					@include minW(992) {
						top: vwd(8px);
					}

					@include minW(1440) {
						top: 8px;
					}
				}
			}
		}
	}
}