.card-reason {
	padding: 0 vwm(32px);

	@include minW(640) {
		padding: 0 vwt(64px);
	}

	@include minW(992) {
		display: flex;
		margin: 0 -48px;
	}

	&__thumb {
		display: flex;
		justify-content: center;

		@include minW(992) {
			justify-content: flex-end;
		}
	}

	&__thumb,
	&__body {
		@include minW(992) {
			width: 50%;
			flex-basis: 50%;
			padding: 0 48px;
		}
	}
}