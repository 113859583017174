.header {
	background-color: #292929;
	color: $white-color;
	padding: 16px 0;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 99;
	transition: all 0.3s ease-out;

	&__body {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 32px;
		transition: all 0.3s ease-out;
	}

	&__left {
		display: flex;
		align-items: center;
		position: relative;
	}

	&__right {
		display: flex;
		align-items: center;
		position: relative;
	}

	&--transparent {
		background-color: transparent;
	}

	&__menu {
		overflow: hidden;
		transition: all 0.3s ease-out;
	}

	&--move {
		background-color: #292929;
	}

	&--small {
		.header {
			&__body {
				margin-bottom: 0;
			}

			&__menu {
				height: 0;
			}
		}
	}
}

.logo {
	display: block;
	width: vwm(160px);

	@include minW(640) {
		width: vwt(160px);
	}

	@include minW(992) {
		width: vwd(160px);
	}

	@include minW(1440) {
		width: 160px;
	}
}

.contact-link {
	display: flex;
	align-items: center;
	margin-right: vwm(24px);

	@include minW(640) {
		margin-right: vwt(24px);
	}

	@include minW(992) {
		margin-right: vwd(24px);
	}

	@include minW(1440) {
		margin-right: 24px;
	}

	.icon {
		margin-right: 4px;
	}
}

.lang {
	user-select: none;
	display: none;

	@include minW(992) {
		display: block;
	}

	&__current {
		position: relative;
		display: flex;
		align-items: center;
		cursor: pointer;

		&::after {
			content: '';
			display: block;
			width: 6px;
			height: 6px;
			border-left: 2px solid $white-color;
			border-bottom: 2px solid $white-color;
			transform: rotate(-45deg);
			margin-top: -8px;
			margin-left: 8px;
		}
	}

	&__list {
		display: none;
		list-style: none;
		position: absolute;
		top: 100%;
		left: 0;
		background-color: $white-color;
		padding: 16px 32px;
		min-width: 240px;
	}

	&__item {
		padding: 16px 0;
		//border-bottom: 1px solid rgba($black-color, 0.15);

		&:last-child {
			border-bottom: 0;
		}
	}

	&__link {
		display: block;
		color: $black-color;
		line-height: 1;
		transition: all 0.3s ease-out;

		@include hover {
			color: $yellow-color;
		}
	}

	&--show {
		.lang {
			&__current {
				&::after {
					transform: rotate(-225deg);
					margin-top: 0;
				}
			}

			&__list {
				display: block;
			}
		}
	}
}

.toggle {
	color: $white-color;
	border: 0;
	background-color: transparent;
	outline: 0;
	padding: 6px;
	margin-right: -6px;

	&:focus {
		outline: 0;
	}
}

.navbar {
	display: flex;
	list-style: none;
	justify-content: center;
	margin-bottom: 0;

	&__link {
		color: $white-color;
		font-size: vwm(16px);
		margin: 0 vwm(32px);

		@include minW(640) {
			font-size: vwt(32px);
			margin: 0 vwt(48px);
		}

		@include minW(992) {
			font-size: vwd(16px);
			margin: 0 vwd(32px);
		}

		@include minW(1440) {
			font-size: 16px;
			margin: 0 32px;
		}

		@include hover {
			color: $yellow-color;
		}
	}
}

.user {
	margin: 0 vwm(16px);

	@include minW(640) {
		margin: 0 vwt(16px);
	}

	@include minW(992) {
		margin: 0 vwd(16px);
	}

	@include minW(992) {
		margin: 0 16px;
	}

	&__icon {
		padding: 6px;
		background-color: transparent;
		border: 0;
		outline: 0;
		color: $white-color;
		transition: all 0.3s ease-out;

		&:focus {
			outline: 0;
		}

		@include hover {
			color: $yellow-color;
		}
	}

	&__list {
		display: none;
		list-style: none;
		position: absolute;
		top: 100%;
		right: 0;
		background-color: $white-color;
		padding: 8px 16px;
		margin-bottom: 0;
		min-width: 240px;
		padding: 16px 32px;
	}

	&__item {
		padding: 16px 0;
	}

	&__link {
		display: block;
		color: $black-color;
		line-height: 1;
		transition: all 0.3s ease-out;

		@include hover {
			color: $yellow-color;
		}
	}

	&--show {
		.user {
			&__list {
				display: block;
			}
		}
	}
}

.search {
	&__icon {
		padding: 6px;
    background-color: transparent;
    border: 0;
    outline: 0;
    color: $white-color;
    transition: all 0.3s ease-out;

		&:focus {
			outline: 0;
		}
	}

	&--show {
		.box-search {
			display: block;
		}
	}
}

.box-search {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background-color: $white-color;
	color: $black-color;
	display: none;
	padding: vwm(16px);
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;

	@include minW(992) {
		position: absolute;
		right: 0;
		top: 100%;
		min-width: 642px;
		padding: 32px;
		left: auto;
		height: auto;
	}

	&__group {
		position: relative;
		display: flex;
		align-items: center;
		margin-bottom: 32px;
	}

	&__btn {
		position: absolute;
		padding: 6px;
    background-color: transparent;
    border: 0;
    outline: 0;
    color: $black-color;
    transition: all 0.3s ease-out;
	}

	&__input {
		width: 100%;
		border: 0;
		padding: 16px 16px 16px 32px;
		border-bottom: 1px solid $black-color;
		margin-right: 32px;
		outline: 0;

		&:focus {
			outline: 0;
		}
	}

	&__cancel {
		background-color: transparent;
		border: 0;
		border-bottom: 1px solid $black-color;
		padding: 0;
		line-height: 1;
		font-size: 16px;
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
	}

	&__item {
		margin-bottom: 32px;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.card-region {
	margin-right: 32px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	&__thumb {
		margin-bottom: 6px;
	}

	&__title {
		margin-bottom: 0;
		font-size: 12px;
		font-weight: 400;
	}

	&:last-child {
		margin-right: 0;
	}
}

.history-search {
	position: relative;

	&__item {
		display: flex;
		align-items: center;
		font-size: 16px;
		color: #292929;
		margin-bottom: 16px;

		.icon {
			margin-right: 16px;
		}
	}
}

.result-search {
	position: relative;

	&__item {
		display: flex;
		align-items: center;
		margin-bottom: 16px;
	}

	&__thumb {
		margin-right: 16px;
		width: 12%;
		flex-basis: 12%;
	}

	&__body {
		width: 88%;
		flex-basis: 88%;
	}

	&__region {
		margin-bottom: 0;
		font-size: 12px;
		color: #858585;
	}
}