.card-top {
	position: relative;

	@include minW(992) {
		margin: vwd(22px);
	}

	@include minW(1440) {
		margin: 22px;
	}

	&__body {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: #000;
		padding: vwm(32px) 0;

		@include minW(640) {
			padding: vwt(64px) 0;
		}

		@include minW(992) {
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			color: #fff;
			background-color: rgba(#000, 0.2);
		}
	}

	&__location {
		position: relative;
		font-size: vwm(16px);
		margin-bottom: vwm(16px);
		padding-left: vwm(24px);

		@include minW(640) {
			font-size: vwt(32px);
			margin-bottom: vwt(32px);
			padding-left: vwt(48px);
		}

		@include minW(992) {
			font-size: vwd(16px);
			margin-bottom: vwd(16px);
			padding-left: vwd(24px);
		}

		@include minW(1440) {
			font-size: 16px;
			margin-bottom: 16px;
			padding-left: 24px;
		}

		&::before {
			content: '';
			display: block;
			background: url('../img/icon-location-white.svg') no-repeat center;
			background-size: cover;
			width: vwm(16px);
			height: vwm(19px);
			position: absolute;
			left: 0;
			top: 0;

			@include minW(640) {
				width: vwt(32px);
				height: vwt(38px);
			}

			@include minW(992) {
				width: vwd(16px);
				height: vwd(19px);
			}

			@include minW(1440) {
				width: 16px;
				height: 19px;
			}
		}
	}

	&__title {
		font-size: vwm(20px);
		margin-bottom: vwm(16px);
		text-transform: uppercase;

		@include minW(640) {
			font-size: vwt(40px);
			margin-bottom: vwt(32px);
		}

		@include minW(992) {
			font-size: vwd(24px);
			margin-bottom: vwd(16px);
		}

		@include minW(1440) {
			font-size: 24px;
			margin-bottom: 16px;
		}

	}

	&__price {
		display: flex;
		align-items: center;
		font-size: vwm(20px);
		margin-bottom: 0;
		color: $yellow-color;

		@include minW(640) {
			font-size: vwt(40px);
		}

		@include minW(992) {
			font-size: vwd(20px);
		}

		@include minW(1440) {
			font-size: 20px;
		}

		strong {
			font-size: vwm(32px);
			margin-left: vwm(8px);

			@include minW(640) {
				font-size: vwt(64px);
				margin-left: vwt(16px);
			}

			@include minW(992) {
				font-size: vwd(32px);
				margin-left: vwd(8px);
			}

			@include minW(1440) {
				font-size: 32px;
				margin-left: 8px;
			}
		}
	}
}