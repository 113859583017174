.page {
  &--padtop {
    padding-top: vwm(134px);

    @include minW(640) {
      padding-top: vwt(134px);
    }

    @include minW(992) {
      padding-top: vwd(134px);
    }

    @include minW(1440) {
      padding-top: 134px;
    }
  }

  &--home {
    padding-top: 0;
  }
}

.close {
  background-color: transparent;
  border: 0;
  outline: 0;
}

.select {
  position: relative;

  &::before {
    border: 2px solid #000;
    border-right: 0;
    border-top: 0;
    content: " ";
    display: block;
    height: 5px;
    margin-top: -1px;
    pointer-events: none;
    position: absolute;
    top: 50%;
    right: 0;
    transform: rotate(-45deg);
    transform-origin: center;
    width: 5px;
  }

  select {
    cursor: pointer;
    display: block;
    max-width: 100%;
    outline: none;
    appearance: none;
    border-color: transparent;
    border-radius: 0;
    border-style: solid;
    border-width: 0;
    box-shadow: none;
  }
}